export function clearBrowserExtensionInjectionsBeforeHydration() {
  for (const s of document.querySelectorAll(
    [
      "html > *:not(body, head)",
      'script[src*="extension://"]',
      'link[href*="extension://"]',
    ].join(", "),
  )) {
    s.parentNode?.removeChild(s);
  }

  const $targets = {
    html: {
      $elm: document.querySelector("html"),
      allowedAttributes: ["lang", "dir", "class"],
    },
    head: {
      $elm: document.querySelector("head"),
      allowedAttributes: [""],
    },
    body: {
      $elm: document.querySelector("body"),
      allowedAttributes: ["class"],
    },
  };

  for (const [_, target] of Object.entries($targets)) {
    if (!target.$elm) {
      continue;
    }

    for (const attr of target.$elm.getAttributeNames()) {
      if (!target.allowedAttributes.includes(attr)) {
        target.$elm?.removeAttribute(attr);
      }
    }
  }
}
