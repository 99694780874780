import { ampli } from "@/ampli";
import { useEffect } from "react";

export function AmpliInit() {
  useEffect(() => {
    if (!ampli.isLoaded) {
      ampli.load({
        environment: "guidestackproduction",
        client: {
          apiKey: import.meta.env.VITE_AMPLITUDE_API_KEY,
          configuration: { defaultTracking: false },
        },
      });
    }

    if (import.meta.env.MODE === "staging" || import.meta.env.DEV) {
      // biome-ignore lint/suspicious/noExplicitAny: `env` is a custom property that is not defined in the Ampli SDK
      ampli.identify(undefined, { env: "guidestack" } as any);
    }
  }, []);

  return null;
}
