/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { StrictMode, startTransition } from "react";
import { hydrateRoot } from "react-dom/client";
import * as ReactHelmet from "react-helmet-async";
import { HydratedRouter } from "react-router/dom";

import { AmpliInit } from "./third-party/Amplitude";
import { clearBrowserExtensionInjectionsBeforeHydration } from "./third-party/browser-extension";

const { HelmetProvider } = ReactHelmet;

function hydrate() {
  clearBrowserExtensionInjectionsBeforeHydration();

  startTransition(() => {
    hydrateRoot(
      document,
      <StrictMode>
        <HelmetProvider>
          <HydratedRouter />
          <AmpliInit />
        </HelmetProvider>
      </StrictMode>,
    );
  });
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}
